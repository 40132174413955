'use strict'

###*
 # @ngdoc service
 # @name mundoAuthentication.factory:UserPermissions

 # @description

###
angular
  .module 'mundoAuthentication'
  .factory 'UserPermissions', [
    'Restangular'
    '$log'
    '_'
    'RestUtils'
    '$q'
    'MyPermissions'
    '$rootScope'
    (Restangular, $log, _, RestUtils, $q, MyPermissions, $rootScope) ->

      UserPermissionsBase = {}
      UserPermissionsBase.permissions = null
      UserPermissionsBase.loading = false

      UserPermissionsBase.get = () ->
        MyPermissions.one().get()

      UserPermissionsBase.setLoading = (state) ->
        UserPermissionsBase.loading = state

      UserPermissionsBase.reloadPermissions = () ->
        if UserPermissionsBase.loading
          return

        try
          userContextId = $rootScope.user.activeUserContext.id
        catch e
          return

        UserPermissionsBase.setLoading true

        return $q (resolve, reject) ->
          UserPermissionsBase.get().then (result) ->
            UserPermissionsBase.setLoading false

            if result[userContextId]?
              UserPermissionsBase.permissions = result[userContextId]

            resolve()
          , (error) ->
            reject(error)

      UserPermissionsBase.loadPermissions = () ->
        if UserPermissionsBase.permissions?
          return

        UserPermissionsBase.reloadPermissions()

      UserPermissionsBase.check = (permission) ->
        UserPermissionsBase.loadPermissions()

        return _.indexOf(UserPermissionsBase.permissions, permission) > -1

      UserPermissionsBase

    ]
